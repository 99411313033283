import { Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import EditableGrid from '../../Features/EditableGrid';
import { IWydanieDetail } from '../../Models/Wydanie';
import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { handleErros, handleSuccess } from '../../Helpers/AxiosCustomHeaders';
import { AppContext } from '../../App';
import ModalElement from '../../Features/Modal';
import { createMagazynToWydanieDetailApi, searchInMagazynForTowarToAssignToWydanieDetailApi } from '../../Services/MagazynToWzDetailService';
import { magazynColumns } from '../../Models/Magazyn';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/Upload';
import { Debounce } from '../../Helpers/ButtonDebouncing';
import { ClearIcon } from '@mui/x-date-pickers';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { allowOnlyNumbers } from '../../Helpers/TextFieldTypeNumber';
import { BarcodeScannerModal } from '../../Features/BarcodeScannerModal';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface OpenAddConnectionModalProps {
	doubleClickedWydanieDetailRow: IWydanieDetail;
	quantitySum: number;
  closeAddConnectionModalAction: Dispatch<void>;
}
export function OpenAddConnectionModal({ doubleClickedWydanieDetailRow, quantitySum, closeAddConnectionModalAction }: OpenAddConnectionModalProps) {
  const appContext = useContext(AppContext);
	const [total, setTotal] = useState<number>(0);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]); // magazyn(magazyn)
	const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loadingCreateButton, setloadingCreateButton] = useState<boolean>(false);
  const [howManyTake, setHowManyTake] = useState<string>("");
  const [exportFlag, setExportFlag] = useState<boolean>(false);
  const [doubleClickedMagazynRow, setDoubleClickedMagazynRow] = useState<any>(null);
	const [quantitySumLocal, setQuantitySumLocal] = useState<number>(quantitySum);
  const [barcodeTextFieldValue, setBarcodeTextFieldValue] = useState<string>('');
  const [openBarcodeScannerModal, setOpenBarcodeScannerModal] = useState(false);
  const [barcode, setBarcode] = useState<string>("");
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleCloseAddConnectionModal = useCallback(() => {
    closeAddConnectionModalAction();
  }, [closeAddConnectionModalAction])

	const modalContent = () => {
    return (<>
      <h1 style={{ margin: '0 0 20px 0' }}>Ile pobrać?</h1>
			<span style={{ marginTop: "-28px" }}>(potrzebnych jeszcze {doubleClickedWydanieDetailRow.count! - quantitySumLocal})</span>
      <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
				style={{marginBottom: "8px", textAlign: 'center'}}>
				<Grid item xs={12}>
					<FormGroup sx={{alignItems: 'center'}}>
						<FormControlLabel control={
							<Checkbox
								icon={<RadioButtonUncheckedIcon sx={{fontSize: 33}} />}
								checkedIcon={<CheckCircleOutlineIcon sx={{fontSize: 33}} />}
								checked={exportFlag}
								onChange={(event: any, value: boolean) => {setExportFlag(value);}} />
						} label="Export" />
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setHowManyTake(event.target.value)}}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="number"
						name="number"
						type="number"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
						endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
						loading={loadingCreateButton}
						loadingPosition="center"
						variant="contained"
						onClick={addAction} >
					</LoadingButton>
				</Grid>
      </Grid>
    </>)
  }

  const initialize = useCallback(async () => {
		setLoadingInitialization(true);
		await searchInMagazynForTowarToAssignToWydanieDetailApi(doubleClickedWydanieDetailRow.towarId, Number(appContext?.rowsNumberPageModel), page, barcode).then(
			(response: AxiosResponse) => {
				setRows(response.data.results);
				setTotal(response.data.total);
				setLoadingInitialization(false);
			},
			(error: AxiosError) => {
				handleErros(error, appContext);
				setLoadingInitialization(false);
			});
  }, [doubleClickedWydanieDetailRow.towarId, page, barcode, appContext]);
	useEffect(() => { initialize(); }, [initialize, page, barcode]);

  const doubleClickMagazynRowAction = async (row: any) => {
    setDoubleClickedMagazynRow(row);
    setOpenAddModal(true);
  };

  const addAction = async () => {
    if (howManyTake === null || howManyTake === undefined) {
      enqueueSnackbar('Proszę uzupełnić ilość do pobrania.', { variant: 'info' });
    } else {
      setloadingCreateButton(true);
      await createMagazynToWydanieDetailApi(Number(howManyTake), doubleClickedMagazynRow.dostawaDetailId, doubleClickedWydanieDetailRow.id, exportFlag).then(
        async (response: AxiosResponse) => {
					await Debounce(setloadingCreateButton, false);
          initialize();
          setOpenAddModal(false);
          handleSuccess(response);
					if (doubleClickedWydanieDetailRow.count !== null) {
						if (doubleClickedWydanieDetailRow.count! - quantitySumLocal - Number(howManyTake) === 0) {
							handleCloseAddConnectionModal();
						} else {
							setQuantitySumLocal(quantitySumLocal + Number(howManyTake));
						}
					}
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setloadingCreateButton(false);
        }
      )
    }
  };

	return (
		<><h1 style={{ margin: '0 0 20px 0' }}>Pobierz z magazynu</h1>
			{doubleClickedWydanieDetailRow.count !== null && doubleClickedWydanieDetailRow.count! - quantitySumLocal !== 0 &&
				<span style={{ marginTop: "-28px" }}>(potrzebnych jeszcze {doubleClickedWydanieDetailRow.count! - quantitySumLocal})</span>}	
			<Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}} sx={{marginBottom: "5px !important"}}>
				<Grid item xs={12} sm={8} md={3} lg={2} xl={2}>
					<TextField
						fullWidth
						label={"Kod kreskowy"}
						autoComplete="cc-number"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setBarcodeTextFieldValue(event.target.value);
						}}
						onKeyUp={(event) => event.key === "Enter" && setBarcode((event.target as HTMLInputElement).value)}
						onBlur={(event) => setBarcode(event.target.value)}
						value={barcodeTextFieldValue}
						onInput = {(event) => {allowOnlyNumbers(event, false)}}
						InputProps={{
							endAdornment: 
								<InputAdornment position="end" style={{marginRight: '-10px', marginLeft: '0px'}}>
									{(barcodeTextFieldValue === '') ?
									<Tooltip title="Skanuj kod kreskowy" placement="left">
										<IconButton onClick={() => setOpenBarcodeScannerModal(true)}>
											<CropFreeIcon style={{color: 'black'}} />
										</IconButton>
									</Tooltip>
									:
									<IconButton onClick={(event) => {event.preventDefault(); setBarcodeTextFieldValue(''); setBarcode('');}}>
										<ClearIcon />
									</IconButton>}
								</InputAdornment>
						}}
					/>
				</Grid>
			</Grid>
			<EditableGrid
				columns={magazynColumns}
				rows={rows}
				editAction={async () => false}
				loading={loadingInitialization}
				setPaginationModel={paginationModelChange}
				paginationModel={{ pageSize: Number(appContext?.rowsNumberPageModel), page: page }}
				total={total}
				setQueryOptions={() => null}
				doubleClickRowAction={(row: any) => {doubleClickMagazynRowAction(row)}}
				deleteAction={() => {}}
				hideDeleteAction={true}
				hideEditAction={true}
				hideActionColumn={true}
				checkboxSelection={false}
				disableRowSelectionOnClick={false}
				setRowsSelectionModel={(param: any) => { setRowSelectionModel(param); }}
				rowSelectionModel={rowSelectionModel}
				hideFooter={false}
				hideFooterSelectedRowCount={true}
				columnVisibilityModel={{}}
				setColumnVisibilityModel={() => null}
				columnGroupingModel={undefined}
				additionalStyles={undefined} />
			<ModalElement handleClose={() => setOpenAddModal(false)} centerCloseButton={false} open={openAddModal} additionalStyles={{width: '20% !important', minWidth: '500px !important', top: '24% !important'}}
				modalContent={modalContent()}
			/>
			<ModalElement handleClose={() => { setOpenBarcodeScannerModal(false) }} open={openBarcodeScannerModal} centerCloseButton={true} modalContent={
					<BarcodeScannerModal setBarcode={(barcode: string) => {setBarcode(barcode); setBarcodeTextFieldValue(barcode);}} closeBarcodeScannerModalAction={() => setOpenBarcodeScannerModal(false)} />
				} additionalStyles={{top: '7% !important'}}/>
		</>
	);
}